import { useEffect, useContext } from "react";
import { GlobalContext } from "../component/GlobalContext";
import { useLocation } from "react-router-dom";
import ItemBox from "../component/ItemBox";
import ItemList from "../component/ItemList";
import BottomBar from "../component/ps/bottom/BottomBar";
import Skins from "./Skins";
import EditForm from "../component/ps/EditForm";
import Error from "./Error";

import "../sass/styles.scss";
import "./PresentationServer.css";
import Heading from "../component/ps/heading/Heading";
import SkinsConfig from "./SkinsConfig";
import MyModal from "../component/ps/modals/Modal";
import CreateForm from "../component/ps/CreateForm";
import PSConsole from "../component/ps/PSConsole";
import {
  ChakraProvider
} from '@chakra-ui/react';
import DefaultLayout from '../layouts/default';
import theme from '../theme';

const Forms = ({ forms }) => {
  const { settings } = useContext(GlobalContext);

  const handleScrollTop = () => {
    document.getElementById("base").scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <section className="work-zone">
      <Heading
        title={"MY FORMS "}
        subtitle={
          forms &&
          `(${forms.length} ${forms.length === 1 ? "form" : "forms"} found.)`
        }
      />
      {forms && forms.length > 0 ? (
        <div className="container">
          <div
            className={`items-container row ${
              settings.theme.view === "list" ? "items-container-markers" : ""
            }`}
          >
            {forms.map((item, index) => {
              switch (settings.theme.view) {
                case "box":
                  return (
                    <div key={`form-${index}`}>
                      <ItemBox name={item.FORMS} />
                    </div>
                  );
                case "list":
                  return (
                    <div key={`form-${index}`}>
                      <ItemList name={item.FORMS} />
                    </div>
                  );
                default:
                  return <Error />;
              }
            })}
            <span
              className="up icon-chevron-up"
              onClick={handleScrollTop}
            ></span>
          </div>
        </div>
      ) : (
        <NoForms />
      )}
    </section>
  );
};

const NoForms = () => {
  return (
    <div className="no-forms-container">
      <h1 className="no-forms-title"> There are no forms here!</h1>
      <strong>
        <p className="no-forms-text">
          Create new forms or log in to show your forms
        </p>
      </strong>
    </div>
  );
};

const PresentationServer = () => {
  const { userInfo, settings, runCode, forms, setLoading } =
    useContext(GlobalContext);
  const location = useLocation();

  const setForms = () => {
    switch (true) {
      case location.pathname.includes("create_new"):
        return <EditForm setLoading={setLoading} />;
      case location.pathname.includes("presentation_server/home"):
        return settings.console.show ? (
          <PSConsole />
        ) : (
          <Forms forms={forms.current} />
        );
      case location.pathname.includes("presentation_server/skins/config"):
        return <SkinsConfig />;
      case location.pathname.includes("presentation_server/skins"):
        return <Skins />;
      case location.pathname.includes("presentation_server/create_form"):
        return <CreateForm />;
      default:
        break;
    }
  };

  useEffect(() => {
    setLoading(true);
    if (userInfo && userInfo.token) {
      runCode({
        code: "show forms",
        token: userInfo.token,
      })
        .then((data) => {
          if (data.output) {
            forms.current = data.output.sort((a, b) =>
              a["FORMS"].localeCompare(b["FORMS"])
            );
          }
          setTimeout(() => {
            setLoading(false);
          }, 200);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <DefaultLayout navbarVariant="presentation-server" baseStyleWorkaround={true} withFooter={false}>
        <article className="content">
          <div className="background"></div>
          {setForms()}
          {settings.modals.show && <MyModal />}
        </article>
        <BottomBar />
      </DefaultLayout>
    </ChakraProvider>
  );
};

export default PresentationServer;
