import React from "react";

const Table = ({ output, keys }) => {
  const index = keys.indexOf("FORM-CTOR");
  if (index !== -1) {
    const i = keys.indexOf("DATA-SPECS");
    i !== -1 && keys.splice(i, 1);
  }
  return (
    <>
      <table align="center" width="85%" border="1px" key={"table-1"}>
        <thead key={"thead-1"}>
          <tr align="center" border="1px" key={"tr-1"}>
            {keys.map((item, index) => (
              <th key={`th-${index}`}>{item}</th>
            ))}
          </tr>
        </thead>
        <tbody key={"tbody-1"}>
          {output.map((item, index) => {
            return (
              <tr align="center" key={`tr-1.${index}`}>
                {keys.map((key, i) => {
                  return <td key={`td-${i}`}>{item[key].toString()}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default Table;
