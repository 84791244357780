import React, { useEffect } from "react";
import { Link as RouterLink, useParams, useLocation } from "react-router-dom";
import {
  Text,
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Show,
} from "@chakra-ui/react";
import Actions from "./Docs/Actions";
import Introduction from "./Docs/Introduction";
import CreateForm from "./Docs/CreateForm";
import ShowForms from "./Docs/ShowForms";
import RemoveForm from "./Docs/RemoveForm";
import ModifyForm from "./Docs/ModifyForm";
import CreateNew from "./Docs/CreateNew";
import GetCase from "./Docs/GetCase";
import ModifyCase from "./Docs/ModifyCase";
import RemoveCase from "./Docs/RemoveCase";
import DefineFunction from "./Docs/DefineFunction";
import IsUsedBy from "./Docs/IsUsedBy";
import RulesIntro from "./Docs/RulesIntro";
import Validations from "./Docs/Validations";
import UniqueRule from "./Docs/UniqueRule";
import AmazingBooks from "./Docs/AmazingBooks";
import AdvancedExamples from "./Docs/AdvancedExamples";
import Button from "../new_component/atoms/Button";
import DefaultLayout from "../layouts/default";
import WhyFQL from "./Docs/WhyFQL";
import DataIntegrity from "./Docs/DataIntegrity";

const MenuHeader = ({ children }) => (
  <Box align="left" w="232px" mt="15px">
    <Text variant="sidebar">{children}</Text>
  </Box>
);

const MenuButton = ({ to, children }) => (
  <Tab as={RouterLink} to={to}>
    {children}
  </Tab>
);

export const Docs = () => {
  const { section = "introduction" } = useParams();
  const tabIds = [
    "why_fql",
    "introduction",
    "data_integrity",
    "create_form",
    "show_forms",
    "remove_form",
    "modify_form",
    "create_new",
    "get_case",
    "modify_case",
    "remove_case",
    "define_function",
    "is_used_by",
    "rules_intro",
    "validations",
    "actions",
    "unique_rule",
    "amazing_books",
    "advanced_examples",
  ];
  const currentIndex = tabIds.findIndex((id) => id === section);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const location = useLocation();
  useEffect(() => {
    onClose();
  }, [location, onClose]);

  const tabList = (
    <TabList
      overflowX="hidden"
      overflowY="auto"
      maxH="calc(100vh - 70px)"
      pos="sticky"
      top="70px"
      gap={1}
    >
      <MenuHeader>Getting Started</MenuHeader>
      <MenuButton to="/docs/why_fql">Why FQL?</MenuButton>
      <MenuButton to="/docs/introduction">Introduction</MenuButton>
      <MenuButton to="/docs/data_integrity">Data Integrity</MenuButton>
      <MenuHeader>Commands</MenuHeader>
      <MenuButton to="/docs/create_form">Create Form</MenuButton>
      <MenuButton to="/docs/show_forms">Show Forms</MenuButton>
      <MenuButton to="/docs/remove_form">Remove Form</MenuButton>
      <MenuButton to="/docs/modify_form">Modify Form</MenuButton>
      <MenuButton to="/docs/create_new">Create New</MenuButton>
      <MenuButton to="/docs/get_case">Get Case</MenuButton>
      <MenuButton to="/docs/modify_case">Modify Case</MenuButton>
      <MenuButton to="/docs/remove_case">Remove Case</MenuButton>
      <MenuButton to="/docs/define_function">Define Function</MenuButton>
      <MenuButton to="/docs/is_used_by">Is Used By</MenuButton>
      <MenuHeader>Rules</MenuHeader>
      <MenuButton to="/docs/rules_intro">Rules Intro</MenuButton>
      <MenuButton to="/docs/validations">Validations</MenuButton>
      <MenuButton to="/docs/actions">Actions</MenuButton>
      <MenuButton to="/docs/unique_rule">Unique</MenuButton>
      <MenuHeader>Examples</MenuHeader>
      <MenuButton to="/docs/amazing_books">Amazing Books</MenuButton>
      <MenuButton to="/docs/advanced_examples">Advanced Examples</MenuButton>
    </TabList>
  );

  return (
    <DefaultLayout
      withFooter={false}
      navbarLeftButtons={
        <Show below="lg">
          <Button icon="bars" fontSize="lg" variant="link" onClick={onOpen} />
        </Show>
      }
    >
      <Tabs
        orientation="vertical"
        index={currentIndex}
        variant="sidebar"
        maxW="1240px"
        m="0 auto"
        isLazy
        lazyBehavior="unmount"
      >
        <Show above="lg">{tabList}</Show>
        <Show below="lg">
          <Drawer isOpen={isOpen} onClose={onClose} placement="left">
            <DrawerOverlay />
            <DrawerContent>
              <DrawerHeader>
                <DrawerCloseButton top={5} left={6} />
              </DrawerHeader>
              <DrawerBody>{tabList}</DrawerBody>
            </DrawerContent>
          </Drawer>
        </Show>
        <TabPanels>
          <TabPanel>
            <WhyFQL />
          </TabPanel>
          <TabPanel>
            <Introduction />
          </TabPanel>
          <TabPanel>
            <DataIntegrity />
          </TabPanel>
          <TabPanel>
            <CreateForm />
          </TabPanel>
          <TabPanel>
            <ShowForms />
          </TabPanel>
          <TabPanel>
            <RemoveForm />
          </TabPanel>
          <TabPanel>
            <ModifyForm />
          </TabPanel>
          <TabPanel>
            <CreateNew />
          </TabPanel>
          <TabPanel>
            <GetCase />
          </TabPanel>
          <TabPanel>
            <ModifyCase />
          </TabPanel>
          <TabPanel>
            <RemoveCase />
          </TabPanel>
          <TabPanel>
            <DefineFunction />
          </TabPanel>
          <TabPanel>
            <IsUsedBy />
          </TabPanel>
          <TabPanel>
            <RulesIntro />
          </TabPanel>
          <TabPanel>
            <Validations />
          </TabPanel>
          <TabPanel>
            <Actions />
          </TabPanel>
          <TabPanel>
            <UniqueRule />
          </TabPanel>
          <TabPanel>
            <AmazingBooks />
          </TabPanel>
          <TabPanel>
            <AdvancedExamples />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </DefaultLayout>
  );
};

export default Docs;
