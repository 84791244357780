import React from "react";
import getCaretCoordinates from "textarea-caret";
import { apiBaseURL } from "../config";

import "./Autocomplete.css";

const Autocomplete = () => {
  const forbiddenKeys = ["Escape", "Shift", "Enter", "Alt", "Meta", "Control"];
  let options = ["create", "show", "get", "modify", "remove", "define"];

  document.body.addEventListener("keyup", async (e) => {
    const div = document.getElementById("connected");
    if (div) {
      const connected = div.className === "false" ? false : true;
      const autocomplete_box = document.getElementById("autocomplete_box");
      const inputArea = document.getElementsByName(
        "react-console-emulator__input"
      )[0];
      if (connected) {
        if (e.key === " " || e.key === "Backspace") {
          const code = inputArea.value;
          options = await fetch(`${apiBaseURL}/autocomplete`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ code: code }),
          })
            .then((res) => res.json())
            .then((data) => {
              return data.suggestions;
            });
        }

        if (e.target === inputArea) {
          if (forbiddenKeys.includes(e.key) || inputArea.value === "") {
            autocomplete_box.className = "autocomplete_box";
            return 0;
          }
          const code = inputArea.value.split(" ");
          updateOptions(options, code[code.length - 1]);

          autocomplete_box.children.length > 0
            ? (autocomplete_box.className = "autocomplete_box show")
            : (autocomplete_box.className = "autocomplete_box");

          const pos = getCaretCoordinates(e.target);
          const pos2 = inputArea.getBoundingClientRect();
          document.documentElement.style.setProperty("--x", pos2.top);
          document.documentElement.style.setProperty(
            "--y",
            Math.min(pos.left, pos2.width)
          );
        } else if (e.target.id === "autocomplete_box" && e.key === "Enter") {
          return 0;
        }
      }
    }
  });

  const handleClick = (e) => {
    const inputArea = document.getElementsByName(
      "react-console-emulator__input"
    )[0];
    const autocomplete_box = document.getElementById("autocomplete_box");
    const options = e.target.children;
    if (options.length === 0) {
      autocomplete_box.className = "autocomplete_box";
      insertText(inputArea, e.target.value);
      inputArea.focus();
    } else if (e.target.id === "autocomplete_box" && e.key === "Enter") {
      autocomplete_box.className = "autocomplete_box";
      insertText(inputArea, e.target[e.target.selectedIndex].text);
      inputArea.focus();
    }
  };

  const insertText = (inputArea, text) => {
    const input = inputArea.value.split(" ");
    if (input[input.length - 1] === "") {
      inputArea.value = inputArea.value + text;
      return 0;
    }
    if (text.includes(input[input.length - 1])) {
      input.pop();
      inputArea.value = input.join(" ") + ` ${text}`;
      return 0;
    }
    inputArea.value = inputArea.value + ` ${text}`;
  };

  const updateOptions = (options, code) => {
    const actual_options = [];
    if (code !== "") {
      options.map((item) => {
        if (item.includes(code)) actual_options.push(item);
        return 0;
      });
    } else actual_options.push(...options);
    document.getElementById("autocomplete_box").innerHTML = actual_options
      .map(
        (item) =>
          `<option class="options" key=${item} value=${item}>
          ${item}
        </option>`
      )
      .join("");
  };

  return (
    <>
      <select
        className="autocomplete_box"
        id="autocomplete_box"
        size="4"
        disabled={false}
        onClick={handleClick}
        onKeyDown={handleClick}
      />
    </>
  );
};

export default Autocomplete;
