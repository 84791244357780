import { useState, useEffect } from "react";
import { motion } from "framer-motion";

import pcframe from "../resources/pc2.png";
import "./ImageContainer.css";

function ImageContainer({ children }) {
  const [imgLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    if (imgLoaded) {
      setTerminalPosition();
    }
  }, [imgLoaded]);

  const setTerminalPosition = () => {
    const height = imgLoaded
      ? document.getElementById("pcframe").offsetHeight
      : 1049;
    const width = imgLoaded
      ? document.getElementById("pcframe").offsetWidth
      : 1030;

    document.documentElement.style.setProperty("--image-height", height * 0.75);
    document.documentElement.style.setProperty("--image-width", width * 0.669);
    document.documentElement.style.setProperty("--image-top", height * 0.07);
    document.documentElement.style.setProperty("--image-left", width * 0.133);
  };

  window.onresize = () => setTerminalPosition();

  const setProps = () => {
    const prompts = document.getElementsByName(
      "react-console-emulator__content"
    )[0];
    prompts.style.height = "85%";
  };

  return (
    <motion.div
      className="image-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { delay: 0.02 } }}
      exit={{ opacity: 0 }}
      onLoad={setProps}
    >
      <img
        src={pcframe}
        alt="PC-frame"
        className="image"
        id="pcframe"
        onLoad={() => setImageLoaded(true)}
      />
      <div className="components-container">{children}</div>
    </motion.div>
  );
}

export default ImageContainer;
