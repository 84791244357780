import React, { useState, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { GlobalContext } from "../../component/GlobalContext";
import {
  Card,
  CardHeader,
  CardBody,
  Stack,
  StackDivider,
  Editable,
  EditablePreview,
  EditableInput,
  Tooltip,
  UnorderedList,
  ListItem,
  Button,
  Box,
  Flex,
  Spacer,
  useToast,
} from '@chakra-ui/react';
import DataItem from './CreateForm/DataItem';
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";

class DataRow {
  constructor({ name = '', type = 'text' }) {
    this.name = name;
    this.type = type;
  }
}

class SimpleDataRow extends DataRow {
  constructor(props) {
    super(props);
    const { isRequired = true, isUnique = false } = props;
    this.isRequired = isRequired;
    this.isUnique = isUnique;
  }

  toFql() {
    const { name, type, isRequired, isUnique } = this;
    return `${name} ${type}${isRequired ? ' not null' : ''}${isUnique ? ' unique' : ''}`;
  }
}

class ReferenceDataRow extends DataRow {
  constructor(props) {
    super(props);
    this.type = 'reference';
    const { referenceForm = '', referenceData = [], min = null, max = null, uniqueness = null } = props;
    this.referenceForm = referenceForm;
    this.referenceData = referenceData;
    this.min = min;
    this.max = max;
    this.uniqueness = uniqueness;
  }
  toFql() {
    const { name, referenceForm, referenceData, min, max, uniqueness } = this;
    const cardinality = min === null && max === null ? null
      : max === null ? `${min}..many`
      : min === max ? `${min}`
      : `${min}..${max}`;
    const referenceTo = referenceData.length <= 1
      ? `${referenceForm}.${referenceData[0]}`
      : `${referenceForm}.(${referenceData.join(', ')})`;
    const uniquenessLabel = uniqueness === null ? null
      : uniqueness === 'totally-unique' ? 'totally unique'
      : uniqueness;
    return [name, 'references', cardinality, referenceTo, uniquenessLabel].filter(x => x !== null).join(' ');
  }
}

const makeDataRow = (props = {}) => {
  return props.type === 'reference'
    ? new ReferenceDataRow(props)
    : new SimpleDataRow(props);
};

const CreateForm = () => {
  const toast = useToast();
  const { userInfo, runCode } = useContext(GlobalContext);
  const [formName, setFormName] = useState('');
  const [dataRows, setDataRows] = useState([makeDataRow()]);
  const toFql = () => (
    `create form ${formName} (${dataRows.map(d => d.toFql()).join(', ')})`
  );
  const onSubmit = () => {
    const code = toFql();
    console.log(code);
    const toastId = toast({
      title: 'In progress',
      description: 'Creating the form...',
      status: 'loading',
      position: 'bottom-right',
    });
    runCode({ code, token: userInfo.token })
      .then((data) => {
        console.log(data);
        if (data.msg.includes('ERROR')) {
          toast.update(toastId, {
            status: 'error',
            title: 'Error',
            description: data.msg,
            isClosable: true,
          });
        }
        else {
          toast.update(toastId, {
            status: 'success',
            title: 'Successfully created',
            description: data.msg,
            isClosable: true,
          });
        }
      })
      .catch((err) => {
        toast.update(toastId, {
          status: 'error',
          title: 'Error',
          description: err.toString(),
          isClosable: true,
        });
      });
  };
  return (
    <Box>
      <Card my={8} bg="secondaryBg" color="text">
        <CardHeader px={8} pt={6} pb={4}>
          <Editable placeholder="Input form name (required)" startWithEditView={true} onChange={(value) => setFormName(() => value)} fontSize='xl' fontWeight='semibold'>
            <Tooltip label="Click to edit" shouldWrapChildren={true}>
              <EditablePreview
                _hover={{ background: 'component.background.200' }}
                {...(formName
                  ? null
                  : { color: 'component.text.300' })} />
            </Tooltip>
            <EditableInput color="text" />
          </Editable>
        </CardHeader>
        <CardBody pt={2}>
          <Stack
            as={UnorderedList} divider={<StackDivider />} spacing={4}>
            {dataRows.map((row, i) => (
              <ListItem mx={3} pl={1} key={`data-row-${i}`} color="gray.500">
                <DataItem name={row.name} type={row.type || 'text'}
                  onChange={(params) => { setDataRows(dataRows.toSpliced(i, 1, makeDataRow(params))) }}
                  onRemove={() => setDataRows(dataRows.toSpliced(i, 1))}
                />
              </ListItem>
            ))}
            <Button variant="link" w="auto" onClick={() => setDataRows(dataRows.concat(makeDataRow()))} leftIcon={<FA icon="plus" />}>Add a new data</Button>
          </Stack>
        </CardBody>
      </Card>
      <Flex>
        <Button variant="link" as={RouterLink} to="/old/presentation_server/home">
          <FA icon="arrow-left" />
          Cancel and go back
        </Button>
        <Spacer />
        <Button onClick={onSubmit} type="submit" size="md">Create a form</Button>
      </Flex>
    </Box>
  );
};

export default CreateForm;
