import { useRef, useState, useContext } from "react";
import { motion } from "framer-motion";
import Heading from "./heading/Heading";
import { Select, MenuItem, Box, Chip } from "@mui/material";

import { GlobalContext } from "../GlobalContext";

const CreateForm = () => {
  const { userInfo, settings, setSettings, runCode } =
    useContext(GlobalContext);
  const [activeTab, setActiveTab] = useState(true);
  const [error, setError] = useState({ msg: "", value: false });
  const errorR = useRef(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const forms = useRef([]);
  const formsProps = useRef([]);
  const [selectedForm, setSelectedForm] = useState("");
  const [selectedProps, setSelectedProps] = useState([]);
  const [dataType, setDataType] = useState("");
  const refConstraint = useRef("");

  const dataTypes = [
    "text",
    "number",
    "boolean",
    "date",
    "time",
    "file",
    "image",
  ];

  const handleSetRefTab = () => {
    setActiveTab(false);
    forms.current = [];
    formsProps.current = [];
    setSelectedForm("");
    setSelectedProps([]);
    setDataType("");
    runCode({ code: "show forms", token: userInfo.token })
      .then((data) => {
        data.output.map((item) => {
          forms.current.push(item["FORMS"]);
        });
        setError({ msg: "", value: false });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClearData = () => {
    setError({ msg: "", value: false });
    errorR.current = false;
    setData([]);
    document.getElementById("cf-form-name").value = "";
  };

  const handleCreateForm = () => {
    setSettings({
      ...settings,
      message: { ...settings.message, show: false },
    });
    setError({ ...error, value: false });
    errorR.current = false;

    const formName = document.getElementById("cf-form-name").value;

    if (formName === undefined || formName === "") {
      setError({ msg: "Form Name can not be empty", value: true });
      errorR.current = true;
    } else {
      if (data[data.length - 1] === ",") data.pop();

      const code = `create form ${formName.toLowerCase()}(${data.join(", ")})`;

      runCode({ code: code, token: userInfo.token })
        .then((data) => {
          setSettings({
            ...settings,
            message: {
              ...settings.message,
              status: data.msg.split(" ")[0].toLowerCase(),
              show: true,
              data: data.msg,
              fn: () => {},
            },
          });
          if (!data.msg.includes("ERROR")) {
            handleClearData();
            handleClearFields();
            setActiveTab(true);
          }
        })
        .catch((err) => {
          setSettings({
            ...settings,
            message: {
              ...settings.message,
              status: "error",
              show: true,
              data: err.message,
              fn: () => {},
            },
          });
        });
    }
  };

  const handleClearFields = () => {
    const inputs = document.getElementsByClassName("cf-input");
    setSelectedForm("");
    setSelectedProps([]);

    for (let i = 0; i < inputs.length; i++) {
      const input = inputs[i];
      switch (input.type) {
        case "text":
          input.value = "";
          break;
        case "number":
          input.value = "";
          break;
        case "select-one":
          input.value = "---";
          break;
        case "checkbox":
          input.checked = false;
          break;
        default:
          break;
      }
    }
  };

  const isRepeated = (value) => {
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      if (item.split(" ").includes(value)) return true;
    }
    return false;
  };

  const handleAddData = () => {
    setError({ ...error, value: false });
    errorR.current = false;
    const inputs = document.getElementsByClassName("cf-input");
    const code = [];

    for (let i = 0; i < inputs.length; i++) {
      const input = inputs[i];
      switch (input.id) {
        case "cf-data-label":
          if (input.value !== undefined && input.value !== "")
            if (!isRepeated(input.value)) {
              code.push(input.value);
            } else {
              setError({
                msg: "Data Label already exists",
                value: true,
              });
              errorR.current = true;
            }
          else {
            setError({ msg: "Fill the required (*) fields", value: true });
            errorR.current = true;
          }
          if (dataType !== "") code.push(dataType);
          else {
            setError({ msg: "Fill the required (*) fields", value: true });
            errorR.current = true;
          }
          break;
        case "cf-null":
          !input.checked && code.push("not null");
          break;
        case "cf-unique":
          input.checked && code.push("unique");
          break;
        default:
          break;
      }
    }
    if (!errorR.current) {
      data.push(code.join(" "));
      handleClearFields();
      setDataType("");
    }
  };

  const handleAddReference = () => {
    setError({ ...error, value: false });
    errorR.current = false;
    const inputs = document.getElementsByClassName("cf-input");
    const cardinality = [];
    const code = [];

    if (selectedForm === "" || selectedProps.length === 0) {
      setError({ msg: "Fill the required (*) fields", value: true });
      errorR.current = true;
      return;
    }

    for (let i = 0; i < inputs.length; i++) {
      const input = inputs[i];
      switch (input.id) {
        case "cf-ref-data-label":
          if (input.value !== undefined && input.value !== "")
            if (!isRepeated(input.value)) {
              code.push(input.value);
            } else {
              setError({
                msg: "Data Label already exists",
                value: true,
              });
              errorR.current = true;
            }
          else {
            setError({ msg: "Fill the required (*) fields", value: true });
            errorR.current = true;
          }
          break;
        case "cf-ref-min":
          const refMax = inputs[i + 1];
          if (input.value !== "") {
            if (refMax.value !== "") {
              const maxCard = parseInt(refMax.value);
              if (!isNaN(maxCard)) {
                if (parseInt(input.value) < maxCard)
                  cardinality.push(`${input.value}..${maxCard}`);
                else {
                  setError({
                    msg: "Min cases are bigger than Max cases",
                    value: true,
                  });
                  errorR.current = true;
                }
              } else {
                cardinality.push(`${input.value}..many`);
              }
            } else {
              input.value !== "0" && cardinality.push(input.value);
            }
          } else {
            if (refMax.value !== "") {
              const maxCard = parseInt(refMax.value);
              cardinality.push(`0..${!isNaN(maxCard) ? maxCard : "many"}`);
            } else {
              break;
            }
          }
          break;
        default:
          break;
      }
    }
    if (!errorR.current) {
      data.push(
        `${code.join(" ")} references ${
          cardinality.length > 0 ? `${cardinality.join("")} ` : ""
        }${selectedForm}.${
          selectedProps.length > 1
            ? `(${selectedProps.join(", ")})`
            : selectedProps[0]
        } ${refConstraint.current}`
      );
      handleClearFields();
    }
  };

  const handleFetchData = async (value) => {
    setLoading(true);
    formsProps.current = [];

    if (value && value !== "") {
      await runCode({
        code: `show forms ${value}`,
        token: userInfo.token,
      })
        .then(async (data) => {
          data.output[0]["DATA-SPECS"].map((item) => {
            formsProps.current.push(item["LABEL"]);
          });
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  const handleSelectDataProps = (e) => {
    const {
      target: { value },
    } = e;
    setSelectedProps(typeof value === "string" ? value.split(",") : value);
  };

  const handleSelectForm = (e) => {
    const {
      target: { value },
    } = e;
    setSelectedForm(value);
    handleFetchData(value);
    setSelectedProps([]);
  };

  const handleSetDataType = (e) => {
    const {
      target: { value },
    } = e;
    setDataType(value);
  };

  const handleSetConstraint = (e) => {
    switch (e.target.id) {
      case "cf-repeated":
        refConstraint.current = "";
        break;
      case "cf-unique":
        refConstraint.current = "unique";
        break;
      case "cf-totally-unique":
        refConstraint.current = "totally unique";
        break;
      default:
        break;
    }
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 30 * 4.5 + 5,
        width: 200,
      },
    },
  };

  return (
    <section className="work-zone">
      <Heading title="CREATE NEW FORM" subtitle="" />
      <div className="create-form">
        <div className="create-form box left">
          <input
            className="cf-form-name-input"
            type="text"
            id="cf-form-name"
            placeholder="Form Name"
          />
          <div className="cf-form-data">
            {data.map((item, index) => {
              return <FormParam data={data} item={item} index={index} />;
            })}
          </div>
          <div
            style={{
              position: "absolute",
              display: "flex",
              flexDirection: "row",
              bottom: "3%",
              right: "10px",
              marginRight: "5px",
              width: "180px",
            }}
          >
            <div
              className="btn btn-secondary"
              style={{
                width: "50%",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              onClick={handleClearData}
            >
              CLEAR
            </div>
            <div
              className="btn btn-primary"
              style={{
                width: "50%",
                marginLeft: "10px",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              onClick={handleCreateForm}
            >
              CREATE
            </div>
          </div>
        </div>
        <div className="create-form box right ">
          <div className="cf-tabs">
            <div
              className={`cf-tab ${activeTab ? "active" : ""}`}
              onClick={() => {
                setActiveTab(true);
              }}
            >
              <p>Form Data</p>
            </div>
            <div
              className={`cf-tab ${!activeTab ? "active" : ""}`}
              onClick={handleSetRefTab}
            >
              <p>Reference Other Form</p>
            </div>
          </div>
          <div className="cf-inputs">
            {activeTab && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="cf-inputs-container"
              >
                <motion.div>
                  <label htmlFor="cf-data-label">Data Label *</label>
                  <input
                    className="cf-input"
                    type="text"
                    id="cf-data-label"
                    required
                  />
                </motion.div>
                <motion.div>
                  <label htmlFor="cf-data-type">Data Type *</label>
                  <Select
                    className="cf-input"
                    id="cf-data-type"
                    defaultValue={""}
                    value={dataType}
                    onChange={handleSetDataType}
                    style={{
                      minWidth: "44%",
                      maxWidth: "73%",
                      height: "30px",
                    }}
                    MenuProps={MenuProps}
                  >
                    {dataTypes.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </motion.div>
                <motion.div>
                  <label htmlFor="cf-null">Can be null</label>
                  <input
                    className="cf-input check"
                    type="checkbox"
                    id="cf-null"
                  />
                </motion.div>
                <motion.div>
                  <label htmlFor="cf-unique">Unique</label>
                  <input
                    className="cf-input check"
                    type="checkbox"
                    id="cf-unique"
                  />
                </motion.div>
                <div
                  className="btn btn-secondary col-xs-2 col-lg-3"
                  style={{
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  onClick={handleAddData}
                >
                  {"<< ADD"}
                </div>
              </motion.div>
            )}
            {!activeTab && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="cf-inputs-container"
              >
                <motion.div>
                  <label htmlFor="cf-ref-data-label">Data Label *</label>
                  <input
                    className="cf-input"
                    type="text"
                    id="cf-ref-data-label"
                    required
                  />
                </motion.div>
                <motion.div>
                  <label htmlFor="cf-ref-form">Form *</label>
                  <Select
                    className="cf-input"
                    id="cf-ref-form"
                    defaultValue={""}
                    onChange={handleSelectForm}
                    value={selectedForm}
                    style={{
                      minWidth: "44%",
                      maxWidth: "73%",
                      height: "30px",
                    }}
                    MenuProps={MenuProps}
                  >
                    {forms.current.length > 0 ? (
                      forms.current.map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value={"NO FORMS"} disabled>
                        NO FORMS
                      </MenuItem>
                    )}
                  </Select>
                </motion.div>
                <motion.div>
                  <label htmlFor="cf-ref-data">Data *</label>
                  <Select
                    className="cf-input"
                    id="cf-ref-data"
                    multiple
                    value={selectedProps}
                    onChange={handleSelectDataProps}
                    style={{
                      minWidth: "44%",
                      maxWidth: "73%",
                      // height: "30px",
                      height: "fit-content",
                    }}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {formsProps.current.length > 0 ? (
                      formsProps.current.map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="---" disabled>
                        ---
                      </MenuItem>
                    )}
                  </Select>
                </motion.div>
                <motion.div>
                  <label htmlFor="cf-ref-min">Min Cases</label>
                  <input className="cf-input" type="number" id="cf-ref-min" />
                </motion.div>
                <motion.div>
                  <label htmlFor="cf-ref-max">Max Cases</label>
                  <input className="cf-input" type="text" id="cf-ref-max" />
                </motion.div>
                <motion.div className="radio">
                  <label htmlFor="cf-repeated">Can be repeated</label>
                  <input
                    className="cf-input radio-button"
                    name="ref-constraint"
                    type="radio"
                    id="cf-repeated"
                    defaultChecked
                    onChange={handleSetConstraint}
                  />
                </motion.div>
                <motion.div className="radio">
                  <label htmlFor="cf-unique">Only once per group</label>
                  <input
                    className="cf-input radio-button"
                    name="ref-constraint"
                    type="radio"
                    id="cf-unique"
                    onChange={handleSetConstraint}
                  />
                </motion.div>
                <motion.div className="radio">
                  <label htmlFor="cf-totally-unique">
                    Only once in only one group
                  </label>
                  <input
                    className="cf-input radio-button"
                    name="ref-constraint"
                    type="radio"
                    id="cf-totally-unique"
                    onChange={handleSetConstraint}
                  />
                </motion.div>
                <div
                  className="btn btn-secondary col-xs-2 col-lg-3"
                  style={{
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  onClick={handleAddReference}
                >
                  {"<< ADD"}
                </div>
              </motion.div>
            )}
          </div>
          {error.value && <span className="cf-error-message">{error.msg}</span>}
        </div>
      </div>
    </section>
  );
};

const FormParam = ({ data, item, index }) => {
  const { setLoading } = useContext(GlobalContext);
  const [visible, setVisible] = useState(false);

  const handleMouseOver = () => {
    setVisible(true);
  };

  const handleMouseLeave = () => {
    setTimeout(() => {
      setVisible(false);
    }, 200);
  };

  const handleDeleteData = (e) => {
    const index = parseInt(e.target.id.split("-")[2]);
    data.splice(index, 1);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 100);
  };

  return (
    <div
      className="data-container"
      onMouseEnter={handleMouseOver}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <div className="data">{item.toString().toUpperCase()}</div>
      {visible && (
        <span
          className="icon-delete data-icon"
          id={`data-icon-${index}`}
          key={`data-icon-${index}`}
          onClick={handleDeleteData}
        ></span>
      )}
    </div>
  );
};

export default CreateForm;
