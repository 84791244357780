import { useContext, useRef, useState } from "react";
import { GlobalContext } from "../GlobalContext";
import Terminal from "react-console-emulator";
import Heading from "./heading/Heading";
import { apiBaseURL } from "../../config";
import getCaretCoordinates from "textarea-caret";
import Table from "../Table";
import Console from '../../new_component/organisms/Console';

const Autocomplete = () => {
  const forbiddenKeys = ["Escape", "Shift", "Enter", "Alt", "Meta", "Control"];
  let options = ["create", "get", "show", "modify", "remove", "define"];
  const snippetCounter = useRef(0);

  document.body.addEventListener("keyup", async (e) => {
    const autocomplete_box = document.getElementById("autocomplete_box");
    const inputArea = document.getElementsByName(
      "react-console-emulator__input"
    )[0];
    const code = inputArea.value.slice(0, inputArea.selectionStart);
    if (e.key === " " || e.key === "Backspace" || e.key === "Tab") {
      options = await fetch(`${apiBaseURL}/autocomplete`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ code: code }),
      })
        .then((res) => res.json())
        .then((data) => {
          return data.suggestions;
        });
    }

    if (e.target === inputArea) {
      if (forbiddenKeys.includes(e.key) || inputArea.value === "") {
        autocomplete_box.className = "autocomplete_box";
        return 0;
      }
      const tokens = code.split(" ");
      updateOptions(options, tokens[tokens.length - 1], tokens.length - 1);

      autocomplete_box.children.length > 0
        ? (autocomplete_box.className = "autocomplete_box show")
        : (autocomplete_box.className = "autocomplete_box");

      autocomplete_box.style.height = `${options.length * 21.5 + 2}px`;
      const pos = getCaretCoordinates(inputArea, inputArea.selectionStart);
      const pos2 = inputArea.getBoundingClientRect();
      document.documentElement.style.setProperty("--x", pos2.top);
      document.documentElement.style.setProperty(
        "--y",
        Math.min(pos.left - 74, inputArea.getBoundingClientRect().width)
      );
    } else if (e.target.id === "autocomplete_box" && e.key === "Enter") {
      inputArea.removeEventListener("keydown", KeyboardEvent);
      return 0;
    }
  });

  const handleClick = (e) => {
    const inputArea = document.getElementsByName(
      "react-console-emulator__input"
    )[0];
    const autocomplete_box = document.getElementById("autocomplete_box");
    const options = e.target.children;
    if (options.length === 0) {
      autocomplete_box.className = "autocomplete_box";
      if (e.target.value.includes("Snippet")) insertSnippet(e.target.value);
      else insertText(inputArea, e.target.value);
      inputArea.focus();
    } else if (e.target.id === "autocomplete_box" && e.key === "Enter") {
      autocomplete_box.className = "autocomplete_box";
      if (e.target[e.target.selectedIndex].text.includes("Snippet"))
        insertSnippet(e.target[e.target.selectedIndex].text);
      else insertText(inputArea, e.target[e.target.selectedIndex].text);
      inputArea.focus();
    }
  };

  const insertText = (inputArea, text) => {
    const inputText = inputArea.value;
    const leftPart = inputText.slice(0, inputArea.selectionStart).replace(/\S*$/, '');
    const cursorPos = leftPart.length + text.length;
    inputArea.value = leftPart + text + inputText.slice(inputArea.selectionEnd);
    inputArea.selectionStart = cursorPos;
    inputArea.selectionEnd = cursorPos;
    return 0;
  };

  const addSnippetts = (token) => {
    const autocomplete_box = document.getElementById("autocomplete_box");
    const snippets = [
      "CreateForm",
      "DefineRule",
      "RemoveForms",
      "ShowForms",
      "GetCase",
      "ModifyCase",
    ];

    snippets.filter(item => item.match(new RegExp(token, 'i'))).forEach((item) => {
      const option = document.createElement("option");
      option.value = `${item} Snippet`;
      option.style.fontStyle = "italic";
      option.textContent = `${item} Snippet`;
      autocomplete_box.appendChild(option);
    });
  };

  const updateOptions = (options, token, index) => {
    const actual_options = [];
    if (token !== "") {
      options.map((item) => {
        if (item.includes(token)) actual_options.push(item);
        return 0;
      });
    } else actual_options.push(...options);
    const autocomplete_box = document.getElementById("autocomplete_box");
    autocomplete_box.innerHTML = actual_options
      .map(
        (item) =>
          `<option class="options" key=${item} value=${item}>
          ${item}
        </option>`
      )
      .join("");
    if (index === 0) {
      addSnippetts(token);
    }
  };

  const insertSnippet = (snippet) => {
    snippetCounter.current = 0;
    switch (snippet) {
      case "CreateForm Snippet":
        handleCreateFormSnippet();
        break;

      case "DefineRule Snippet":
        handleDefineRuleSnippet();
        break;

      case "RemoveForms Snippet":
        handleRemoveFormSnippet();
        break;

      case "ShowForms Snippet":
        handleShowFormSnippet();
        break;

      case "GetCase Snippet":
        handleGetCaseSnippet();
        break;

      case "ModifyCase Snippet":
        handleModifyCaseSnippet();
        break;

      default:
        break;
    }
  };

  const handleCreateFormSnippet = () => {
    const controller = new AbortController();
    const inputArea = document.getElementsByName(
      "react-console-emulator__input"
    )[0];
    if (inputArea) {
      inputArea.value = `create form <type form name> ( <params...> )`;
      inputArea.select();
      inputArea.selectionStart = 12;
      inputArea.selectionEnd = 28;
      inputArea.addEventListener(
        "keydown",
        (e) => {
          if (e.key === "Tab") {
            e.preventDefault();
            snippetCounter.current = snippetCounter.current + 1;
            const textInput = inputArea.value.split(" ");
            let index = textInput.indexOf("<params...>");

            let start = 0;
            for (let i = 0; i < index; i++) {
              const element = textInput[i];
              start = start + element.length + 1;
            }
            inputArea.select();
            inputArea.selectionStart = start;
            inputArea.selectionEnd = inputArea.selectionStart + 11;
            controller.abort();
          }
        },
        { signal: controller.signal }
      );
    }
  };

  const handleShowFormSnippet = () => {
    const inputArea = document.getElementsByName(
      "react-console-emulator__input"
    )[0];
    if (inputArea) {
      inputArea.value = `show forms <[ form[s] ]>`;
      inputArea.select();
      inputArea.selectionStart = 11;
      inputArea.selectionEnd = 24;
    }
  };

  const handleRemoveFormSnippet = () => {
    const inputArea = document.getElementsByName(
      "react-console-emulator__input"
    )[0];
    if (inputArea) {
      inputArea.value = `remove forms <form[s]>`;
      inputArea.select();
      inputArea.selectionStart = 13;
      inputArea.selectionEnd = 22;
    }
  };

  const handleDefineRuleSnippet = () => {
    const inputArea = document.getElementsByName(
      "react-console-emulator__input"
    )[0];
    const controller = new AbortController();
    if (inputArea) {
      inputArea.value = `define rule <type rule name>: on <form_name> when <conditions> is invalid`;
      inputArea.select();
      inputArea.selectionStart = 12;
      inputArea.selectionEnd = 28;
      inputArea.addEventListener(
        "keydown",
        (e) => {
          if (e.key === "Tab") {
            e.preventDefault();
            snippetCounter.current = snippetCounter.current + 1;
            const textInput = inputArea.value.split(" ");
            let index = -1;
            let sum = 0;
            if (snippetCounter.current === 1) {
              index = textInput.indexOf("<form_name>");
              sum = 11;
            } else {
              index = textInput.indexOf("<conditions>");
              sum = 12;
              controller.abort();
            }
            let start = 0;
            for (let i = 0; i < index; i++) {
              const element = textInput[i];
              start = start + element.length + 1;
            }
            inputArea.select();
            inputArea.selectionStart = start;
            inputArea.selectionEnd = inputArea.selectionStart + sum;
          }
        },
        { signal: controller.signal }
      );
    }
  };

  const handleGetCaseSnippet = () => {
    const inputArea = document.getElementsByName(
      "react-console-emulator__input"
    )[0];
    const controller = new AbortController();
    if (inputArea) {
      inputArea.value = `get <type form name> ( <[params]> ) with <[conditions]>`;
      inputArea.select();
      inputArea.selectionStart = 4;
      inputArea.selectionEnd = 20;
      inputArea.addEventListener(
        "keydown",
        (e) => {
          if (e.key === "Tab") {
            e.preventDefault();
            snippetCounter.current = snippetCounter.current + 1;
            const textInput = inputArea.value.split(" ");
            let index = -1;
            let sum = 0;
            if (snippetCounter.current === 1) {
              index = textInput.indexOf("<[params]>");
              sum = 10;
            } else {
              index = textInput.indexOf("<[conditions]>");
              sum = 14;
              controller.abort();
            }
            let start = 0;
            for (let i = 0; i < index; i++) {
              const element = textInput[i];
              start = start + element.length + 1;
            }
            inputArea.select();
            inputArea.selectionStart = start;
            inputArea.selectionEnd = inputArea.selectionStart + sum;
          }
        },
        { signal: controller.signal }
      );
    }
  };

  const handleModifyCaseSnippet = () => {
    const inputArea = document.getElementsByName(
      "react-console-emulator__input"
    )[0];
    const controller = new AbortController();
    if (inputArea) {
      inputArea.value = `modify <type form name> ( <params> ) with <[conditions]>`;
      inputArea.select();
      inputArea.selectionStart = 7;
      inputArea.selectionEnd = 23;
      inputArea.addEventListener(
        "keydown",
        (e) => {
          if (e.key === "Tab") {
            e.preventDefault();
            snippetCounter.current = snippetCounter.current + 1;
            const textInput = inputArea.value.split(" ");
            let index = -1;
            let sum = 0;
            if (snippetCounter.current === 1) {
              index = textInput.indexOf("<params>");
              sum = 8;
            } else {
              index = textInput.indexOf("<[conditions]>");
              sum = 14;
              controller.abort();
            }
            let start = 0;
            for (let i = 0; i < index; i++) {
              const element = textInput[i];
              start = start + element.length + 1;
            }
            inputArea.select();
            inputArea.selectionStart = start;
            inputArea.selectionEnd = inputArea.selectionStart + sum;
          }
        },
        { signal: controller.signal }
      );
    }
  };

  return (
    <>
      <select
        className="autocomplete_box"
        id="autocomplete_box"
        size={7}
        disabled={false}
        onClick={handleClick}
        onKeyDown={handleClick}
        style={{ zIndex: 100, overflowY: "auto", width: "fit-content" }}
      />
    </>
  );
};

const PSConsole = () => {
  const { settings, setSettings, fqlToken, userInfo } =
    useContext(GlobalContext);
  const [connection, setConnection] = useState({ host: apiBaseURL });
  const scheme = window.location.protocol;
  const [termUserInfo, setTermUserInfo] = useState({ token: userInfo.token });
  const effectRan = useRef(false);

  const getToken = async (host) => {
    await fetch(`${scheme}//${host}/new_guest_token`, {
      method: "POST",
    })
      .then((resp) => resp.json())
      .then((data) => {
        setTermUserInfo({ token: data.token });
      })
      .catch((err) => console.log(err));
  };

  const runCode = async (command) => {
    return await fetch(`${connection.host}/run_code`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        code: command,
        token: termUserInfo.token,
        fql_token: fqlToken.current,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        const keys = [];
        if (data.output && data.output.length !== 0) {
          for (const k in data.output[0]) {
            keys.push(k);
          }
        }
        if (data.fql_token) {
          setSettings({
            ...settings,
            userData: {
              ...settings.userData,
              fqlToken: {
                "FORM-NAME": data.fql_token["FORM-NAME"],
                TOKEN: data.fql_token.TOKEN,
              },
            },
          });
        }
        return (
          <>
            {data.msg}
            {data.output ? <Table output={data.output} keys={keys} /> : ""}
          </>
        );
      })
      .catch((err) => `Bad FQL code: ${err}`);
  };

  const commands = {
    echo: {
      description: "Echo a passed string.",
      usage: "echo <string>",
      fn: (...args) => {
        return args.join(" ");
      },
    },

    token: {
      description: "Returns the user token identifier",
      fn: () => termUserInfo.token,
    },

    connected: {
      description:
        "Returns a boolean value if the terminal is connected to an engine or not.",
      fn: () => {
        return `Connected to host ${connection.host}`;
      },
    },

    disconnect: {
      description: "Disconnects the backend engine.",
      fn: () => {
        if (apiBaseURL !== connection.host) {
          setTermUserInfo({ token: userInfo.token });
          setConnection({ host: apiBaseURL });
          return `Disconected from ${connection.host}! \n Connected to host ${apiBaseURL}`;
        } else {
          return "Not connected to a different host";
        }
      },
    },

    "?": {
      description: "Shows the help",
      usage: "?",
      fn: () =>
        "help - Show a list of available commands.\nclear - Empty the terminal window.\necho - Echo a passed string. - echo <string>\nconnected - Returns a boolean value if the terminal is connected to an engine or not.\ndisconnect - Disconnects the backend engine.\n? - Shows the help - ?\nconnect - Connects the terminal emulator to a backend engine on the specified <address> - connect <address>\n",
    },

    connect: {
      description:
        "Connects the terminal emulator to a backend engine on the specified <address>",
      usage: "connect <address>",
      fn: async (...args) => {
        if (args.length === 0 || args.length > 1)
          return "One argument expected";

        return fetch(`${scheme}//${args[0]}/api/connect`, {
          method: "GET",
        })
          .then((res) => res.json())
          .then(async () => {
            setConnection({ host: `${scheme}//${args[0]}/api` });
            const apiHost = apiBaseURL.split("//")[1].split("/")[0];
            if (args[0] === apiHost) {
              setTermUserInfo({ token: userInfo.token });
            } else await getToken(args[0]);
            return `Done! Connected to ${args[0]}`;
          })
          .catch((err) => {
            console.error(err);
            return "Connection error. Check the address and try again!";
          });
      },
    },

    create: {
      description:
        "Using the previously connected engine, executes the code and returns a message",
      usage: "fql <fql_code>",
      fn: async (...args) => {
        return await runCode(`create ${args.join(" ")}`);
      },
    },
    get: {
      description:
        "Using the previously connected engine, executes the code and returns a message",
      usage: "fql <fql_code>",
      fn: async (...args) => {
        return await runCode(`get ${args.join(" ")}`);
      },
    },
    modify: {
      description:
        "Using the previously connected engine, executes the code and returns a message",
      usage: "fql <fql_code>",
      fn: async (...args) => {
        return await runCode(`modify ${args.join(" ")}`);
      },
    },
    remove: {
      description:
        "Using the previously connected engine, executes the code and returns a message",
      usage: "fql <fql_code>",
      fn: async (...args) => {
        return await runCode(`remove ${args.join(" ")}`);
      },
    },
    define: {
      description:
        "Using the previously connected engine, executes the code and returns a message",
      usage: "fql <fql_code>",
      fn: async (...args) => {
        return await runCode(`define ${args.join(" ")}`);
      },
    },
    show: {
      description:
        "Using the previously connected engine, executes the code and returns a message",
      usage: "fql <fql_code>",
      fn: async (...args) => {
        return await runCode(`show ${args.join(" ")}`);
      },
    },
  };

  return (
    <section className="work-zone">
      <Heading title="FQL" subtitle="CONSOLE" />
      <div className="ps-console-container">
        <Console commands={commands} userToken={userInfo.token} />
      </div>
    </section>
  );
};
export default PSConsole;
