import React, { useState, useRef, useEffect, useContext } from 'react';
import { GlobalContext } from "../../../component/GlobalContext";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  Stack,
  HStack,
  SimpleGrid,
  Wrap,
  WrapItem,
  Box,
  Center,
  Text,
  Spacer,
  FormControl,
  FormLabel,
  Button,
  IconButton,
  Switch,
  Select,
  Checkbox,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react';
import AutosizeInput from 'react-18-input-autosize';
import {
  AutoComplete,
  AutoCompleteInput as AutoCompleteInputInner,
  AutoCompleteItem,
  AutoCompleteList,
  AutoCompleteTag,
} from "@choc-ui/chakra-autocomplete";
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import BorderBox from '../../atoms/BorderBox';

const AutoCompleteInput = ({ isInvalid, ...props }) => (
  <Box borderWidth={isInvalid ? 2 : 1} borderColor={isInvalid ? "#e53e3e" : "gray.200"} borderRadius={6}>
    <AutoCompleteInputInner border="none" {...props} />
  </Box>
);

const SimpleTypeAttributes = ({ isRequired, isUnique, onChange, ...options }) => {
  return (
    <Box {...options}>
      <HStack spacing={4}>
        <Switch name="isRequired" onChange={onChange} defaultChecked={isRequired} display="flex" alignItems="center">Required?</Switch>
        <Switch name="isUnique" onChange={onChange} defaultChecked={isUnique} display="flex" alignItems="center">Unique?</Switch>
      </HStack>
    </Box>
  );
};

const ReferenceTypeAttributes = ({ referenceForm, referenceData, min, max, uniqueness, forms, dataItems, onChange, ...options }) => {
  const [isLimitEnabled, setIsLimitEnabled] = useState(false);
  const [minCase, setMinCase] = useState(Number.isInteger(min) ? min : 0);
  const [maxCase, setMaxCase] = useState(Number.isInteger(max) ? max : 1);
  const [isMaxUnlimited, setIsMaxUnlimited] = useState(false);
  const [isUniquenessEnabled, setIsUniquenessEnabled] = useState(false);
  const uniquenessSelectRef = useRef(null);

  const onChangeForm = (e) => {
    onChange && onChange({ referenceForm: e.target.value, referenceData: [] });
  };
  const onChangeData = (values) => {
    onChange && onChange({ referenceData: values });
  };

  const onChangeRangeEnabled = (e) => {
    const isEnabled = e.target.checked;
    setIsLimitEnabled(isEnabled);
    if (isEnabled) {
      onChange && onChange({ min: minCase, max: isMaxUnlimited ? null : maxCase });
    }
    else {
      onChange && onChange({ min: null, max: null });
    }
  };
  const onChangeMin = (value) => {
    const newVal = parseInt(value, 10);
    setMinCase(isNaN(newVal) ? value : newVal);
    if (!isNaN(newVal)) {
      if (Number.isInteger(maxCase) && maxCase < newVal) {
        setMaxCase(newVal);
        if (isLimitEnabled) {
          onChange && onChange({ min: newVal, max: newVal });
        }
      }
      else if (isLimitEnabled) {
        onChange && onChange({ min: newVal });
      }
    }
  };
  const onChangeMax = (value) => {
    const newVal = parseInt(value, 10);
    setMaxCase(isNaN(newVal) ? value : newVal);
    if (!isNaN(newVal)) {
      if (Number.isInteger(minCase) && newVal < minCase) {
        setMinCase(newVal);
        if (isLimitEnabled) {
          onChange && onChange({ min: newVal, max: newVal });
        }
      }
      else if (isLimitEnabled) {
        onChange && onChange({ max: newVal });
      }
    }
  };
  const onChangeMaxUnlimited = (e) => {
    setIsMaxUnlimited(e.target.checked);
    if (onChange) {
      if (e.target.checked) {
        onChange({ max: null });
      }
      else {
        onChange({ max: maxCase });
      }
    }
  };
  const onChangeIsUniquenessEnabled = (e) => {
    setIsUniquenessEnabled(e.target.checked);
    if (onChange) {
      if (e.target.checked) {
        onChange({ uniqueness: uniquenessSelectRef.current.value });
      }
      else {
        onChange({ uniqueness: null });
      }
    }
  };
  const onChangeUniqueness = (e) => {
    if (onChange && isUniquenessEnabled) {
      onChange({ uniqueness: e.target.value });
    };
  };

  return (
    <Box {...options} size="sm">
      <Stack spacing={5}>
        <SimpleGrid columns={2} spacing={5}>
          <Box>
            <FormControl>
              <FormLabel>Refers To</FormLabel>
              <Select name="referenceForm" onChange={onChangeForm} placeholder="Form to be referred" selected={referenceForm}>
                {(forms || []).map((form, i) => (
                  <option key={`reference-form-${i}`} value={form}>{form}</option>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box>
            <FormControl>
              <FormLabel>Data</FormLabel>
              <AutoComplete openOnFocus multiple onChange={onChangeData} value={referenceData}>
                <AutoCompleteInput placeholder="Input data name to refer">
                  {({ tags }) => tags.map((tag, tid) => <AutoCompleteTag label={tag.label} key={tid} onRemove={tag.onRemove} />)}
                </AutoCompleteInput>
                <AutoCompleteList color="component.text.100" bg="secondaryBg">
                  {(dataItems || []).filter(x => !referenceData.includes(x)).map((data, i) => (
                    <AutoCompleteItem key={`reference-data-item-${i}`} value={data}>{data}</AutoCompleteItem>
                  ))}
                </AutoCompleteList>
              </AutoComplete>
            </FormControl>
          </Box>
        </SimpleGrid>
        <Stack spacing={3}>
          <Box>
            <Switch display="flex" alignItems="center" onChange={onChangeRangeEnabled}>
              Set the range of number of rows?
            </Switch>
            <BorderBox display={isLimitEnabled ? '' : 'none'} my={3} maxW="250px">
              <HStack spacing={3} alignItems="top">
                <Box>
                  <FormControl>
                    <FormLabel>Min</FormLabel>
                    <NumberInput value={minCase} min={0} onChange={onChangeMin}>
                      <NumberInputField placeholder="Min" />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </FormControl>
                </Box>
                <Box>
                  <FormControl>
                    <FormLabel>Max</FormLabel>
                    <NumberInput value={isMaxUnlimited ? 'many' : maxCase} min={1} onChange={onChangeMax} isDisabled={isMaxUnlimited}>
                      <NumberInputField placeholder="Max" />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                    <Box mt={1}>
                      <Checkbox onChange={onChangeMaxUnlimited}>Unlimited</Checkbox>
                    </Box>
                  </FormControl>
                </Box>
              </HStack>
            </BorderBox>
          </Box>
          <Box>
            <Switch display="flex" alignItems="center" onChange={onChangeIsUniquenessEnabled}>
              Rows must be unique?
            </Switch>
            <BorderBox display={isUniquenessEnabled ? '' : 'none'} my={3} maxW="350px">
              <FormControl>
                <FormLabel>Uniqueness</FormLabel>
                <Select ref={uniquenessSelectRef} onChange={onChangeUniqueness}>
                  <option value="unique">Appear only once per group</option>
                  <option value="totally-unique">Appear only once in all groups</option>
                </Select>
              </FormControl>
            </BorderBox>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};

const DataItem = ({ name, type, onChange, onRemove, ...props }) => {
  const { userInfo, runCode } = useContext(GlobalContext);
  const [simpleDataAttr, setSimpleDataAttr] = useState({ isRequired: true, isUnique: false });
  const [referenceForm, setReferenceForm] = useState(null);
  const [referenceDataAttr, setReferenceDataAttr] = useState({
    referenceData: [],
    min: null,
    max: null,
    uniqueness: null,
  });
  const [dataItem, setDataItem] = useState({ name, type });
  const [forms, setForms] = useState([]);
  const [formDataOptions, setFormDataOptions] = useState([]);
  const onReferenceAttrChange = (attrs) => {
    for (let key of Object.keys(attrs)) {
      if (key === 'referenceForm') {
        setReferenceForm(attrs[key]);
        onChange && onChange(Object.assign({}, dataItem, { referenceForm: attrs[key] }));
      }
      else {
        const newDataAttr = Object.assign({}, referenceDataAttr, attrs);
        setReferenceDataAttr(newDataAttr);
        onChange && onChange(Object.assign({}, dataItem, newDataAttr));
      }
    }
  };
  const onUpdateAttr = ({ name, value }) => {
    if (name === 'name' || name === 'type') {
      const newDataItem = Object.assign({}, dataItem, { [name]: value });
      setDataItem(newDataItem);
      onChange && onChange(Object.assign({}, newDataItem, newDataItem.type === 'reference' ? referenceDataAttr : simpleDataAttr));
    }
    else if (dataItem.type === 'reference') {
      onReferenceAttrChange({ [name]: value });
    }
    else {
      const newDataAttr = Object.assign({}, simpleDataAttr, { [name]: value });
      onChange && onChange(Object.assign({}, dataItem, newDataAttr));
      setSimpleDataAttr(newDataAttr);
    }
  };
  const onUpdateType = (newType) => {
    onUpdateAttr({ name: 'type', value: newType });
  };
  const onValueChange = (e) => {
    const {name, type} = e.target;
    if (name) {
      const value = type === 'checkbox' ? e.target.checked : e.target.value;
      onUpdateAttr({ name, value });
    }
    else {
      console.error(`"name" is not set in ${e.target}`);
    }
  };
  useEffect(() => {
    if (userInfo) {
      runCode({ code: "show forms", token: userInfo.token })
        .then((data) => {
          setForms(data.output.map(item => item["FORMS"]).flat());
        });
    }
  }, [userInfo]);
  useEffect(() => {
    if (userInfo) {
      setFormDataOptions([]);
      // XXX: Not quite good. `referenceData` also should be an individual state.
      setReferenceDataAttr(Object.assign({}, referenceDataAttr, { referenceData: [] }));
      if (referenceForm) {
        // XXX: referenceForm may contain any special characters
        runCode({ code: `show forms ${referenceForm}`, token: userInfo.token })
          .then((data) => {
            // FIXME: Check if "output" is not empty, or get a JS error.
            setFormDataOptions(data.output[0]['DATA-SPECS'].map(spec => spec['LABEL']));
          });
      }
    }
  }, [referenceForm]);

  return (
    <Popover trigger="hover" placement="left-start" openDelay={0} closeDelay={0} preventOverflow={false} gutter={0}>
      <PopoverTrigger>
        <Stack spacing={4} color="layout.text">
          <Wrap>
            <WrapItem alignItems="center">
              <AutosizeInput placeholder='Data Name' name="name" defaultValue={name} onChange={onValueChange} inputStyle={{ background: 'transparent', fontSize: "120%", fontWeight: 'bold' }} />
            </WrapItem>
            <WrapItem alignItems="center" mr={5}>
              <Box>
                <Text fontSize="120%" as="span">:&nbsp;&nbsp;</Text>
                <Menu>
                  <MenuButton as={Button} variant="link" size="lg" fontWeight="normal" fontFamily="monospace">{type}</MenuButton>
                  <MenuList color="component.text.100">
                    <MenuOptionGroup title="Choose type" name="type" value={type} type="radio" onChange={onUpdateType}>
                      <MenuItemOption value="text" fontFamily="monospace">text</MenuItemOption>
                      <MenuItemOption value="number" fontFamily="monospace">number</MenuItemOption>
                      <MenuItemOption value="boolean" fontFamily="monospace">boolean</MenuItemOption>
                      <MenuItemOption value="date" fontFamily="monospace">date</MenuItemOption>
                      <MenuItemOption value="time" fontFamily="monospace">time</MenuItemOption>
                      <MenuItemOption value="file" fontFamily="monospace">file</MenuItemOption>
                      <MenuItemOption value="image" fontFamily="monospace">image</MenuItemOption>
                      <MenuItemOption value="reference" fontFamily="monospace">reference</MenuItemOption>
                    </MenuOptionGroup>
                  </MenuList>
                </Menu>
              </Box>
            </WrapItem>
            <Spacer />
            <WrapItem alignItems="center">
              <SimpleTypeAttributes {...simpleDataAttr} display={type === 'reference' ? 'none' : ''} onChange={onValueChange} />
            </WrapItem>
          </Wrap>
          <ReferenceTypeAttributes
            referenceForm={referenceForm}
            {...referenceDataAttr}
            display={type !== 'reference' ? 'none' : ''}
            forms={forms}
            dataItems={formDataOptions}
            onChange={onReferenceAttrChange}
          />
        </Stack>
      </PopoverTrigger>
      <PopoverContent h="30px" w="50px" bg="transparent" boxShadow="none" border="none">
        <Center w="30px" color="gray.300" h="100%">
          <IconButton variant="unstyled" size="xs" icon={<FA icon="xmark" fontSize="2em" />} onClick={onRemove} />
        </Center>
      </PopoverContent>
    </Popover>
  );
};

export default DataItem;
