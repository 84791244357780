import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Grid,
  GridItem,
  Box,
  VStack,
  Heading,
  Text,
  Link,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import DefaultLayout from "../layouts/default";
import Button from "../new_component/atoms/Button";
import { GlobalContext } from "../component/GlobalContext";
import theme from "../theme";

const Icon = ({ icon, ...props }) =>
  icon && (
    <Box my={5}>
      <FA icon={icon} {...props} />
    </Box>
  );

const HugeButton = ({ href, title, description, icon, ...props }) => (
  <Link as={RouterLink} to={href} _hover={{ color: theme.colors.text }}>
    <Box
      p="25px"
      align="center"
      bgColor="brand.100"
      borderRadius="30px"
      transitionDuration="0.2s"
      _hover={{ marginTop: "-10px" }}
      {...props}
    >
      {icon && <Icon fontSize="6em" icon={icon} />}
      <Heading as="h4" size="lg">
        {title}
      </Heading>
      <Text align="left">{description}</Text>
    </Box>
  </Link>
);

const UserHome = () => (
  <DefaultLayout>
    <VStack
      maxW="80ch"
      minH="80vh"
      margin="0 auto"
      justify="center"
      gap={10}
      position="relative"
    >
      <Box justify="center" position="absolute" top={0}>
        <GlobalContext.Consumer>
          {({ loggedIn }) =>
            loggedIn || (
              <Alert status="warning">
                <AlertIcon />
                <Text fontSize="sm" m={0}>
                  You are in guest mode.{" "}
                  <Link>
                    <b>Login </b>
                  </Link>
                  or
                  <Link>
                    <b> Sign Up</b>
                  </Link>{" "}
                  to keep your application data alive.
                </Text>
              </Alert>
            )
          }
        </GlobalContext.Consumer>
      </Box>
      <Box>
        <Grid align="top" gap="20px" templateColumns="repeat(2, 1fr)">
          <GridItem w="100%">
            <HugeButton
              title="FQL Console"
              description="Type your FQL Commands directly to interact with the App Base Engine."
              icon="laptop"
              href="/old/fqlconsole"
            />
          </GridItem>
          <GridItem w="100%">
            <HugeButton
              title="Presentation Server"
              description="Web/Graphical User Interface to create & use forms, without writing any code."
              icon="diagram-project"
              href="/old/presentation_server/home"
            />
          </GridItem>
        </Grid>
      </Box>
      <Box>
        <Button icon="book" variant="link" href="/docs">
          See Documentation
        </Button>
      </Box>
    </VStack>
  </DefaultLayout>
);

export default UserHome;
