import { extendTheme } from "@chakra-ui/react";
import "@fontsource/poppins/700.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/400.css";
import "@fontsource/mulish/500.css";
import "@fontsource/mulish/400.css";

export const defaultTheme = extendTheme({
  fonts: {
    heading: `'Poppins', sans-serif`,
    body: `'Mulish', sans-serif`, // XXX: Doesn't work because it's overwritten by the base style.
  },
  colors: {
    text: "#2d2d2d",
    bg: "rgb(248, 249, 255)",
    secondaryBg: "#fff",
    component: {
      text: {
        100: "#2d2d2d",
        300: "#A0AEC0",
      },
      background: {
        100: "#fff",
        200: "#EDF2F7",
      },
    },
    layout: {
      text: "#2d2d2d",
      bg: "rgb(248, 249, 255)",
      footerBg: "#2d2d2d",
      footerText: "rgb(248, 249, 255)",
    },
    accent: {
      pink: "rgba(242, 191, 175, 1)",
      yellow: "rgba(248, 213, 126, 1)",
      darkgray: "#606567",
      white: "rgb(248, 249, 255)",
      red: "#d9534f",
    },
    brand: {
      100: "#e5f4f2",
      400: "#10a389",
      500: "#009379"
    },
  },
  styles: {
    global: {
      "body, #base": {
        overflow: "initial",
      },
      ".super": {
        height: "initial",
      },
      ".docs": {
        h2: {
          fontSize: "4xl",
          mb: "20px",
        },
        h3: {
          fontSize: "2xl",
          mt: "2rem",
          mb: "1rem",
        },
        h4: {
          fontSize: "xl",
          mt: "1.5rem",
          mb: "0.8rem",
        },
        p: {
          textAlign: "justify",
        },
      },
      ".new_presentation_server": {
        ul: {
          mb: 0,
        },
      },
      "label": {
        margin: 0,
      },
      ".background": {
        background: 'bg',
      },
    },
  },
  components: {
    Text: {
      baseStyle: {
        fontSize: ["14px", "16px", "16px", "16px"],
      },
      variants: {
        lg: {
          fontSize: ["16px", "21px", "21px", "21px"],
        },
        sidebar: {
          fontFamily: "heading",
          fontSize: "0.8rem",
          color: "brand.500",
          textAlign: "left",
          fontWeight: "600",
          mx: "0",
          my: "5px",
        },
      },
    },
    Heading: {
      baseStyle: {
        color: "text",
      },
      variants: {
        section: {
          fontSize: ["2rem", "3rem", "3rem", "3rem"],
          lineHeight: "shorter",
        },
        "page-index": {
          fontSize: "1rem",
          color: "accent.darkgray",
          fontWeight: "600",
        },
      },
    },
    Button: {
      baseStyle: {
        color: "brand.500",
        transitionDuration: "0.1s",
        transitionProperty: "width, height, padding, font-size, border-radius",
        gap: "8px",
      },
      sizes: {
        sm: {
          borderRadius: "10px",
          h: "40px",
          px: "20px",
        },
        md: {
          h: "50px",
          px: "24px",
          fontSize: "sm",
          borderRadius: "16px",
        },
        lg: {
          borderRadius: "18px",
        },
        xl: {
          h: "60px",
          p: "16px 50px",
          borderRadius: "20px",
          fontSize: "16px",
        },
      },
      variants: {
        primary: {
          bg: "brand.500",
          color: "bg",
          _hover: {
            bg: "brand.400",
          },
        },
        secondary: {
          bg: "brand.100",
        },
        outline: {
          color: "brand.500",
          bg: "bg",
          border: "1px solid",
          borderColor: "brand.500",
          _hover: {
            bg: "brand.100",
          },
        },
        link: {
          color: "brand.500",
          px: "20px",
          gap: "4px",
          _hover: {
            color: "brand.400",
          },
        },
        text: {
          fontWeight: "500",
          px: "20px",
          _hover: {
            bg: "#f3f3f3",
          },
        },
        menu: {
          borderRadius: 0,
          color: "text",
          bg: "bg",
          m: 0,
          py: 2,
          minW: 150,
          height: 'auto',
          justifyContent: 'left',
          fontWeight: 'normal',
          _focus: {
            bg: 'gray.100',
          },
        },
      },
      defaultProps: {
        variant: "primary",
        size: 'sm',
      },
    },
    Tabs: {
      variants: {
        sidebar: {
          tab: {
            justifyContent: "left",
            fontSize: "sm",
            fontWeight: "500",
            borderRadius: "6px",
            _selected: {
              bg: "brand.100",
            },
            _hover: {
              color: "text",
              bg: "#eee",
            },
          },
          tablist: {
            p: "10px 20px",
            w: "280px",
          },
          tabpanel: {},
        },
      },
    },
    Textarea: {
      baseStyle: {
        _placeholder: {
          color: "lightgray",
        },
      },
    },
    List: {
      variants: {
        "page-index": {
          item: {
            fontSize: "0.8rem",
            listStyleType: "none",
            lineHeight: "1.8rem",
          },
        },
      },
    },
    Card: {
      variants: {
        outline: {
          container: {
            _hover: {
              bg: "#eee !important",
            },
          },
        },
      },
    },
    Link: {
      variants: {
        footer: {
          _hover: {
            color: "#ccc",
          },
        }
      },
    },
    Logo: {
      variants: {
        light: {
          color: '#fff'
        },
        dark: {
          color: '#fff'
        },
      },
    },
    FormLabel: {
      sizes: {
        sm: {
          fontSize: 'xs',
          textTransform: 'uppercase',
          fontWeight: 'bold',
        },
      },
      defaultProps: {
        size: 'sm',
      },
    },
  },
  zIndices: {
    hide: -1,
    base: 0,
    float: 9,
    navbar: 10,
    top: 11,
  },
});

export const futuristTheme = extendTheme({
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  colors: {
    bg: "linear-gradient(120deg, #273740 0%, #1E232D 45%, #1E232D 50%, #432C33 100%)",
    text: '#fff',
    secondaryBg: "hsla(0,0%,42%,.204)",
    component: {
      text: {
        200: "hsla(0,0%,62%,.204)",
        300: "hsla(0,0%,82%,.204)",
        400: "hsla(0,0%,82%,.404)",
      },
      background: {
        200: "hsla(0,0%,42%,.204)",
      },
    },
    layout: {
      bg: "#0a101a",
      text: "rgb(248, 249, 255)",
      footerBg: "#0a101a",
    },
  },
  components: {
    Button: {
      variants: {
        link: {
          color: "brand.400",
          _active: {
            color: "text",
          },
        },
      },
    },
    NumberInput: {
      baseStyle: {
        field: {
          _placeholder: {
            color: '#fff',
          },
        },
      },
    },
  },
}, defaultTheme);

export default defaultTheme;
