import React, { useState, useRef } from "react";
import { useInView } from 'react-intersection-observer';
import {
  Stack,
  VStack,
  HStack,
  Container,
  Heading,
  Text,
  Box,
  Show,
  Input
} from '@chakra-ui/react';
import Typewriter from "react-ts-typewriter";
import { DefaultLayout } from '../layouts/default';
import Button from '../new_component/atoms/Button';
import HeroSection from '../new_component/organisms/HeroSection';
import CodeBlock from "../new_component/atoms/CodeBlock";
import CodeExample from "../new_component/organisms/CodeExample";

const Section = ({ variant, ...props }) => (
  variant === 'round'
    ? <Container w="90%" my="20px" p={{base: "50px 30px" , md: "55px 75px"}} borderRadius="20px" {...props} />
    : <Container w="100%" maxW="auto" p={{base: "50px 30px" , md: "55px 75px"}} {...props} />
);

const LandingPage = () => {
  const [mainRef, mainInView] = useInView({ rootMargin: '-70px', initialInView: true });
  const [buttonsRef, buttonsInView] = useInView({ initialInView: true });
  const [isSampleVisible, setIsSampleVisible] = useState(true);
  const consoleRef = useRef();

  const onClickSample = () => {
    setIsSampleVisible(false);
    consoleRef.current?.focus();
  };

  const sampleCode = `\
create form Countries (
  name text not null unique,
  capital text not null,
  extension number
)

create form Addresses (
  street_number number,
  zip_code number,
  country references Countries.name
)`;

  return (
    <DefaultLayout navbarSize={mainInView ? 'md' : 'sm'} navbarVariant="landing-page">
      <div mt="30px" ref={mainRef}></div>
      <VStack gap={{ base: "20px", lg: "50px" }} pt="20px" pb="100px" fontSize="21px">
        <HeroSection buttonsRef={buttonsRef} fixButtons={!buttonsInView} />
        <Section>
          <Stack gap="40px" direction={['column', 'column', 'column', 'row']}>
            <VStack minW="50%">
              <Heading as="h2" variant="section">
                Build your app<br />without programming
              </Heading>
              <Text variant="lg">
                Presentation server allows to define your data and logic
              </Text>
            </VStack>
            <Box>
              <img src="/images/presentation-server.png" alt="Presentation Server screenshot" />
            </Box>
          </Stack>
        </Section>
        <Section bgColor="accent.yellow">
          <VStack gap="20px">
            <Heading as="h2" variant="section">
              Or, write it in FQL
            </Heading>
            <Text variant="lg" m={0}>
              It can also be written in a declarative way with FQL
            </Text>
            <Box w="750px" maxW="95%" pos="relative">
              <CodeExample
                ref={consoleRef}
                minRows={10}
                color={isSampleVisible ? "transparent" : "inherit"}
                transitionDuration={0}
                onFocus={() => setIsSampleVisible(false)}
              >
                {sampleCode}
              </CodeExample>
              <Box pos="absolute" top={10} w="100%" display={isSampleVisible ? "" : "none"} onClick={onClickSample}>
                <CodeBlock fontSize="md" pt="13px" lineHeight="1.37rem">
                  <Typewriter text={sampleCode} speed={30} loop delay={3000} />
                </CodeBlock>
              </Box>
            </Box>
          </VStack>
        </Section>
        <Section bgColor="accent.pink" variant="round" maxW="auto">
          <VStack gap="20px" align="left">
            <Heading as="h2" variant="section">
              Start building<br />your app today!
            </Heading>
            <Text variant="lg">
              Application Base is easy to get started without sign up.
            </Text>
            <HStack>
              <Button href="/home" icon="arrow-right" size={{base: "md", md: "xl"}}>Get Started</Button>
              <Button href="/docs" variant="link" size={{base: "md", md: "xl"}}>See Documentation</Button>
            </HStack>
          </VStack>
        </Section>
        <Section bgColor="brand.100" variant="round">
          <VStack gap="20px">
            <Heading as="h3" size="xl">Let&#39;s get in touch!</Heading>
            <Text>Got questions? Contact us for quick and friendly support.</Text>
            <Show above="sm">
                <Button href="https://synchronit.com/#contact-form" icon='fa-envelope' size="xl">Contact</Button>
            </Show>
            <Show below="sm">
              <Input size="md" bgColor="bg" variant="outline" placeholder="E-mail address" />
              <Button href="https://synchronit.com/#contact-form" icon="fa-envelope">Contact</Button>
            </Show>
          </VStack>
        </Section>
      </VStack>
    </DefaultLayout>
  );
};

export default LandingPage;
